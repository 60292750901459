import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from "./store";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title : "Select brand - Diageo Promotions",
                metaTags: [
                    {
                        name: 'description',
                        content: 'Description here'
                    },
                    {
                        property: 'og:description',
                        content: 'Description here'
                    }
                ]
            }
        },
        {
            path: '/brand/:brand',
            name: 'brand',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "brand" */ './views/Brand.vue'),
            props: true,
            meta: {
                title : "Select Promotion - Diageo Promotions",
                metaTags: [
                    {
                        name: 'description',
                        content: 'Description here'
                    },
                    {
                        property: 'og:description',
                        content: 'Description here'
                    }
                ]
            }
        },
        {
            // Returns the winners for a promotion
            path: '/brand/:brand/:promotion',
            name: 'promotion',
            // route level code-splitting
            // this generates a separate chunk (promotion.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "promotion" */ './views/Promotion.vue'),
            props: true,
            meta: {
                title : "Diageo Promotions Winners",
                metaTags: [
                    {
                        name: 'description',
                        content: 'Description here'
                    },
                    {
                        property: 'og:description',
                        content: 'Description here'
                    }
                ]
            }
        },
        {
            path: '/error',
            name: 'error',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "error" */ './views/Error.vue'),
            props: true,
            meta: {
                title : "Diageo Promotions - Oops",
                metaTags: [
                    {
                        name: 'description',
                        content: 'Description here'
                    },
                    {
                        property: 'og:description',
                        content: 'Description here'
                    }
                ]
            }
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            // route level code-splitting
            // this generates a separate chunk (privacy.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue'),
            props: true,
            meta: {
                title : "Diageo Promotions - Privacy Policy",
                metaTags: [
                    {
                        name: 'description',
                        content: 'Description here'
                    },
                    {
                        property: 'og:description',
                        content: 'Description here'
                    }
                ]
            }
        }
    ],
    /**
     *
     * @param to
     * @param from
     * @param savedPosition
     * @return {{x: number, y: number}}
     */
    // eslint-disable-next-line
    scrollBehavior (to, from, savedPosition) {
        // return { x: 0, y: 0 }
    },
});
