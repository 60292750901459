<template>
    <div id="app" v-bind:class="activeBrand">
        <video playsinline autoplay muted loop poster="./assets/videos/background/jpg/desktop.jpg" class="bg-video" id="desktop-video">
            <source src="./assets/videos/background/webm/desktop.webm" type="video/webm">
            <source src="./assets/videos/background/mp4/desktop.mp4" type="video/mp4">
        </video>
        <video playsinline autoplay muted loop poster="./assets/videos/background/jpg/mobile.jpg" class="bg-video" id="mobile-video">
            <source src="./assets/videos/background/webm/mobile.webm" type="video/webm">
            <source src="./assets/videos/background/mp4/mobile.mp4" type="video/mp4">
        </video>
        <header>
            <router-link :to="{ name: 'home' }">
                <img src="./assets/images/logos/diageo@3x.png" alt="">
            </router-link>
            <h4>
                <router-link :to="{ name: 'home' }">Promotions</router-link>
            </h4>
        </header>
        <main>
            <router-view/>
        </main>
        <footer class="" id="main-footer">
            <div class="container-fluid bw-logo-container">
                <div class="row py-3">
                    <div class="col" ><img src="./assets/images/johnnie-walker-2.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/crown-royal.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/j-b.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/buchanan-s.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/windsor.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/don-julio.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/ketel.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/ciroc.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/captain-morgan.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/baileys.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/tanquery.png" alt="" class="img-fluid"></div>
                    <div class="col"><img src="./assets/images/guinness.png" alt="" class="img-fluid"></div>
                </div>
            </div>
            <hr>
            <div class="container">
                <div class="row pt-3 text-center">
                    <div class="col text-center">
                        <div id="footer"></div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
    export default {
        mounted() {

            this.$store.dispatch('getBrands');

            document.addEventListener("visibilitychange", () => {
                let videos = document.querySelectorAll('video');
                videos.forEach(video => {
                    if (video.playing) {
                        video.pause();
                    } else {
                        video.play();
                    }
                });

            });


        },
        computed: {
            activeBrand () {
                return this.$store.getters.activeBrand
            }
        }
    };
</script>

<style lang="scss">
    @import "assets/scss/main";
</style>
