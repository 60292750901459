<template>
    <div>
        <section class="page-title outer-space">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1 v-if="homepage.fields">{{ homepage.fields.find(obj => obj.title === 'Page Title').value }}</h1>
                        <p v-if="homepage.fields">{{ homepage.fields.find(obj => obj.title === 'Description').value }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="brand-tiles">
            <div class="container">
                <div class="row">
                    <template v-for="(brand, key) in brands">
                        <div class="col-6 col-md-4 col-lg-3 tilter" v-bind:key="key" v-if="brand.fields.length > 0"
                             data-tilt data-tilt-scale="1.01" data-tilt-max="35">
                            <router-link :to="{
                                    name: 'brand',
                                    params:{
                                        brand: brand.fields.find(obj => obj.title === 'Slug').value
                                    }
                                    }" class="card brand-card mb-5">
                                <div class="card-img-top">
                                    <img :src="brand.fields.find(obj => obj.title === 'Image').imgURL" alt=""
                                         class="img-fluid">
                                </div>
                                <div class="card-footer text-center hvr-sweep-to-bottom">
                                    {{ brand.fields.find(obj => obj.title === 'Name').value }}

                                </div>
                            </router-link>
                        </div>
                    </template>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <router-link :to="{
                            name: 'brand',
                            params:{
                                brand: 'other'
                            }
                            }" class="btn btn-diageo btn-lg hvr-sweep-to-bottom">
                            Other
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'home',

        mounted() {
            this.$store.dispatch('getHomepage');
        },
        data() {
            return {}
        },
        computed: {
            brands() {
                return this.$store.getters.brands;
            },
            homepage() {
                return this.$store.getters.homepage
            }
        },
        methods: {}
    };
</script>

<style lang="scss">


</style>
