import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from './router'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        activeBrand: "",
        activePromotion: "",
        brands: [],
        promotions: [],
        winners: [],
        homepage: {}
    },
    getters: {
        activeBrand: state => {
            return state.activeBrand;
        },
        activePromotion: state => {
            return state.activeBrand;
        },
        brands: state => {
            return state.brands;
        },
        winners: state => {
            return state.winners;
        },
        promotions: state => {
            return state.promotions;
        },
        latestPromotions: (state) => (qty) => {
            return state.promotions.slice(0, (qty))
        },
        getBrand: (state) => (BrandName) => {
            let selectedBrand = null;
            state.brands.forEach((brand) => {
                if (brand.fields.find(obj => obj.title === 'Slug').value === BrandName) {
                    selectedBrand = brand.fields;
                }
            });
            return selectedBrand;
        },
        getPromotion: (state) => (PromotionName) => {
            let selectedPromotion = null;
            state.promotions.forEach(promotion => {
                if (promotion.fields.find(obj => obj.title === 'Slug').value === PromotionName) {
                    selectedPromotion = promotion.fields;
                }
            });
            return selectedPromotion;
        },
        homepage: state => {
            return state.homepage;
        },
    },
    mutations: {
        setBrands(state, brands) {
            state.brands = brands;
        },
        setPromotions(state, promotions) {
            state.promotions = promotions;
        },
        setWinners(state, winners) {
            state.winners = winners;
        },
        addBrand(state, brand) {
            state.brand.push(brand)
        },
        setActiveBrand(state, brand) {
            state.activeBrand = brand;
        },
        setActivePromotion(state, promotions) {
            state.activePromotion = promotions;
        },
        setHomepage(state, homepage) {
            state.homepage = homepage;
        }
    },
    actions: {
        getHomepage({commit}) {
            return axios.get('Homepage.json').then(response => {
                commit('setHomepage', response.data.contents[0]);
            }).catch(() => {
                router.push('/error');
            });
        },
        getBrands(context) {
            return axios.get('BrandList.json').then(response => {
                context.commit('setBrands', response.data.contents)
            }).catch(() => {
                router.push('/error');
            });
        },

        getPromotions(context) {
            return axios.get(this.state.activeBrand + '-Promotions.json').then(response => {
                context.commit('setPromotions', response.data.contents)
            }).catch(() => {
                context.commit('setPromotions', [])
            });
        },

        getWinners(context ) {
            return axios.get(this.state.activePromotion + '-Winners.json').then(response => {
                context.commit('setWinners', response.data.contents)
            }).catch(() => {
                context.commit('setWinners', [])
            });
        }
    }
});
