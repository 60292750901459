require('./bootstrap.js');

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueFuse from 'vue-fuse'

Vue.use(VueFuse);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

router.beforeEach((to, from, next) => {
    switch (to.name) {
        case 'brand':
            store.commit('setActiveBrand', to.params.brand);
            break;
        case 'home':
            store.commit('setActiveBrand', '');
            break;
    }

    document.title = to.meta.title;

    next();
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

// jQuery
;(function() {
    $(function () {
        $('#myTab li:last-child a').tab('show')
    });
});