/**
 * @url: https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing
 */
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
});
try {
    // window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('vanilla-tilt');
} catch (e) {
}