var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"page-title outer-space"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.homepage.fields)?_c('h1',[_vm._v(_vm._s(_vm.homepage.fields.find(obj => obj.title === 'Page Title').value))]):_vm._e(),(_vm.homepage.fields)?_c('p',[_vm._v(_vm._s(_vm.homepage.fields.find(obj => obj.title === 'Description').value))]):_vm._e()])])])]),_c('section',{staticClass:"brand-tiles"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._l((_vm.brands),function(brand,key){return [(brand.fields.length > 0)?_c('div',{key:key,staticClass:"col-6 col-md-4 col-lg-3 tilter",attrs:{"data-tilt":"","data-tilt-scale":"1.01","data-tilt-max":"35"}},[_c('router-link',{staticClass:"card brand-card mb-5",attrs:{"to":{
                                name: 'brand',
                                params:{
                                    brand: brand.fields.find(obj => obj.title === 'Slug').value
                                }
                                }}},[_c('div',{staticClass:"card-img-top"},[_c('img',{staticClass:"img-fluid",attrs:{"src":brand.fields.find(obj => obj.title === 'Image').imgURL,"alt":""}})]),_c('div',{staticClass:"card-footer text-center hvr-sweep-to-bottom"},[_vm._v(" "+_vm._s(brand.fields.find(obj => obj.title === 'Name').value)+" ")])])],1):_vm._e()]})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('router-link',{staticClass:"btn btn-diageo btn-lg hvr-sweep-to-bottom",attrs:{"to":{
                        name: 'brand',
                        params:{
                            brand: 'other'
                        }
                        }}},[_vm._v(" Other ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }